<template>
  <div class="sendcode" @click="onClick">
    <div class="count-down" :class="{ disabled: counting }">
      <span v-if="!counting">{{ translateTitle(text) }}</span>
      <span v-else class="disabled">{{ countDown + "s" }}</span>
    </div>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
export default {
  props: {
    counting: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "获取验证码",
    },
  },
  data() {
    return {
      countDown: 5,
      timer: null,
    };
  },
  methods: {
    translateTitle,
    onClick() {
      if (!this.counting) {
        this.$emit("count");
      }
    },
  },
  watch: {
    counting(val) {
      if (val) {
        this.countDown = 60;
        this.timer = setInterval(() => {
          if (this.countDown == 0) {
            clearInterval(this.timer);
            this.$emit("update:counting", false);
            return;
          }
          this.countDown--;
        }, 1000);
      } else {
        this.countDown = 60;
        clearInterval(this.timer);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sendcode {
  // .count-down {
  //   min-width: 80px;
  //   height: 48px;
  //   color: #fff;
  //   font-size: 14px;
  //   line-height: 48px;
  //   text-align: center;
  //   border-radius: 2px;
  //   // background: rgba(198,27,61, 1);
  //   // background-image: linear-gradient(267deg, #ff486d 110%, #c61b3d 41%);
  //   color: #3a7dff;
  //   // line-height: 1em;
  //   text-align: center;
  //   position: absolute;
  //   right: 10px;
  //   transform: translateY(-50%);
  //   border: 0;
  //   box-sizing: border-box;
  //   cursor: pointer;

  //   /* 禁用状态 */
  //   // &.disabled {
  //   //   color: #a2a5ae;
  //   //   background-color: #e7e7e7;
  //   //   cursor: not-allowed;
  //   //   &:hover {
  //   //     background-color: #e7e7e7;
  //   //     }
  //   //   }
  // }
  .count-down {
    width: 80px;
    color: #3a7dff;
    line-height: 1em;
    text-align: center;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    .disabled {
      color: #9aa1b1;
    }

    span {
      cursor: pointer;
    }
  }
  /* 白色背景 */
  .whiteBg {
    min-width: 90px;
    color: rgba(39, 198, 131, 1);
    border: 1px solid rgba(39, 198, 131, 1);
    background-color: #fff;
    &:hover {
      background-color: rgba(39, 198, 131, 0.2);
    }
    &.disabled {
      border: 1px solid #eeeeee;
      background-color: #fff;
      &:hover {
        background-color: #fff;
      }
    }
  }
}
</style>
